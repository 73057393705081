body {
  font-family: $primaryFont;
  font-size: 16px;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: $headerFont;
  font-weight: 400;
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

a {
  color: inherit;
  font-weight: 700;
  text-decoration: none;
}

button,
input,
select,
option,
textarea {
  font-family: $primaryFont;
  font-size: 1rem;
}

input,
select,
option,
textarea {
  font-size: 1rem;
}

.inputError {
  display: block;
  background-color: $grey;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.25rem 1rem !important;
  margin-bottom: 1rem;
  margin-top: -1rem;
}

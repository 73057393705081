.scroll-to-top {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgba($yellow, 0.75);
  color: $white;
  border-radius: 0.25rem;
  cursor: pointer;
  z-index: 999;
}

.accountView {
  padding-bottom: 3rem;
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    background-color: $lightyellow;
  }

  &__overview {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 900px;

    &--badge {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 150px;
      background-color: $yellow;
      color: white;
      font-size: 2rem;
      font-weight: 700;
      border: 5px solid white;
      border-radius: 50%;
      margin-top: -75px;
      overflow: hidden;
      user-select: none;
      z-index: +1;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .badge__edit {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(darken(yellow, 30%), 0.6);
        opacity: 0;
        transition: 0.2s;
      }

      &:hover .badge__edit {
        opacity: 1;
        transition: 0.2s;
      }
    }

    &--userInfo {
      flex: 0 0 100%;
      padding-top: 5rem;

      p {
        position: relative;
        display: grid;
        grid-template-columns: 200px 1fr;
        max-width: 650px;
        border-bottom: 2px solid $lightgrey;
        padding: 0 1rem 1rem;
        margin: 0 auto 2rem;

        &.password {
          .togglePasswordVisibility {
            position: absolute;
            right: -46px;
            background-color: $lightyellow;
            color: $black;
            border: none;
            width: 30px;
            height: 30px;
            border-radius: 0.25rem;
            transition: 0.2s;

            &:hover {
              background-color: $yellow;
            }
          }
        }
      }
      span {
        font-weight: bold;
      }
    }

    &--logout {
      width: 100%;
      text-align: center;
    }
  }

  @media screen and (max-width: 675px) {
    &__overview {
      &--userInfo {
        p.password {
          .togglePasswordVisibility {
            right: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    &__overview {
      &--editAccount {
        padding-top: 2rem;
      }
      &--userInfo {
        padding-top: 2rem;
        p {
          grid-template-columns: 1fr;
          span {
            padding-bottom: 0.5rem;
          }

          .togglePasswordVisibility {
            bottom: 1rem;
          }
        }
      }
    }
  }
}

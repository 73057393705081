@keyframes slideDownNav {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideUpNav {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
.addRecipeView {
  width: 80%;
  margin: 4rem auto;

  h1 {
    margin: 2rem 0;
  }

  .row {
    display: grid;
    grid-template-columns: 250px auto;
    gap: 0.5rem;
    max-width: 900px;
    margin-bottom: 1rem;

    label {
      margin-right: 1rem;
    }

    input,
    select,
    textarea {
      padding: 0.5rem 1rem;
    }

    textarea {
      width: 100%;
      height: 200px;
    }
  }

  .ingredients {
    .row {
      position: relative;
      grid-template-columns: 170px 170px 1fr auto;

      .adjustIngredientCount {
        position: absolute;
        bottom: 0;
        right: -8rem;

        button {
          border: none;
          background-color: $yellow;
          border: 2px solid $yellow;
          padding: 0.25rem 1rem;

          &:first-child {
            margin-right: 1rem;
          }

          &:nth-child(2) {
            background-color: transparent;
            border: 2px solid $black;
          }
        }
      }
    }
  }

  .preperationSteps {
    .row {
      position: relative;

      .adjustStepCount {
        position: absolute;
        bottom: 0;
        right: -8rem;

        button {
          border: none;
          background-color: $yellow;
          border: 2px solid $yellow;
          padding: 0.25rem 1rem;

          &:first-child {
            margin-right: 1rem;
          }

          &:nth-child(2) {
            background-color: transparent;
            border: 2px solid $black;
          }
        }
      }
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    gap: 1rem;
    border-top: 2px solid $grey;
    padding-top: 3rem;
    margin-top: 3rem;
  }

  .addRecipeModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    a {
      color: $yellow;
      margin-top: 3rem;
    }
  }
}

@media screen and (max-width: 765px) {
  .addRecipeView {
    .row {
      grid-template-columns: 1fr;
    }

    .ingredients {
      .row {
        grid-template-columns: 48% 48%;

        .adjustIngredientCount {
          position: absolute;
          bottom: -2.5rem;
          right: unset;
          left: 0;
        }
      }
      .row:first-child {
        display: none;
      }

      .row:last-child {
        padding-bottom: 1rem;
        margin-bottom: 3rem;
      }
    }

    .preperationSteps {
      .row {
        .adjustStepCount {
          position: absolute;
          bottom: -2.5rem;
          right: unset;
          left: 0;
        }
      }

      .row:last-child {
        padding-bottom: 1rem;
        margin-bottom: 4rem;
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.35);
    z-index: -1;
  }

  &__content {
    width: 90vw;
    height: 90vh;
    background-color: $white;
    overflow-y: auto;
  }
}
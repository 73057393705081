*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
  color: $black;
  overflow-x: hidden;
}

.viewContainer {
  padding-top: 88px;

  @media screen and (max-width: 1000px) {
    padding-top: 80px;
  }
}

.container {
  width: 100%;
  max-width: 1400px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 576px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  user-select: none;

  &:focus-visible {
    outline: none;
  }
}

.primary-btn {
  display: inline-block;
  background-color: $orange;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid $orange;
  border-radius: 0.25rem;
  padding: 1rem 2rem;
  overflow: hidden;
  transition: 0.2s ease-in;

  &.outline {
    background-color: transparent;
    color: $orange;
  }
}

.no-btn {
  color: $orange;
  font-weight: 600;

  &.small {
    font-size: 14px;
  }
}

.btn {
  display: inline-block;
  background-color: $yellow;
  color: $black;
  font-size: 1rem;
  font-weight: 700;
  border: 2px solid $yellow;
  border-radius: 2rem;
  padding: 1rem 2rem;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease-in;

  svg:not(.loader) {
    margin-left: 0.5rem;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background-color: darken($yellow, 10%);
    border-radius: 2rem;
    opacity: 0;
    transform: translateX(-50%);
    transition: 0.2s ease-in;
    z-index: -1;
  }

  &.inverse {
    border: 2px solid $black;
    background-color: transparent;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  &:not(:disabled):hover {
    @media screen and (min-width: 800px) {
      transform: scale(1.02);

      &::after {
        width: 110%;
        opacity: 1;
        transition: 0.2s ease-in;
      }
    }
    transition: 0.2s ease-in;
  }
}

.btn-inverse {
  border: 2px solid $black;
  background-color: transparent;
}

.btn-flat {
  background-color: transparent;
  color: $black;
  font-weight: bold;
  border: none;
  border-bottom: 2px solid $black;

  &.white {
    color: $white;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-bottom: 2px solid $white;
    padding: 0 0.25rem;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 3rem;
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &.column-dir {
    flex-direction: column;
  }

  &.flex-left {
    align-items: flex-start;
  }
}

// GLOBAL STYLING INPUTFIELDS

.input-label {
  display: flex;
  flex-direction: column;
  font-weight: 600;

  &.hor {
    flex-direction: row;
    align-items: center;
  }

  &.small {
    font-size: 14px;
  }
}

input,
select,
textarea {
  background-color: white;
  border: 1px solid rgba($black, 0.6);
  border-radius: 0.25rem;
  padding: 1rem;
  margin-top: 0.5rem;
}

input:focus {
  outline-color: $orange;
}

input::placeholder {
  color: rgba($black, 0.6);
  font-weight: 400;
}

label.custom-checkbox {
  position: relative;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox-inner {
      opacity: 1 !important;
    }

    &:checked ~ span.checkbox .checkbox-inner {
      opacity: 1 !important;
    }
  }

  span.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    background-color: $orange;
    border-radius: 4px;
    margin-right: 0.5rem;

    .checkbox-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      opacity: 0.2;
    }

    &-small {
      width: 16px;
      height: 16px;

      .checkbox-inner {
        font-size: 0.75rem;
      }
    }
  }
}

.input-row-horizontal {
  display: flex;
  gap: 1rem;
}

.password-inputfield {
  position: relative;
  width: 100%;

  input {
    width: 100%;
  }
}
.togglePasswordVisibility {
  position: absolute;
  top: calc(50% - 11px);
  right: 16px;
  color: $black;
  border: none;
  width: 30px;
  height: 30px;
  transition: 0.2s;
}

// .custom-checkbox {
//     position: relative;
//     display: flex;
//     align-items: center;
//     text-transform: lowercase;
//     margin-bottom: 0.75rem;

//     input {
//       position: absolute;
//       opacity: 0;
//       cursor: pointer;
//       height: 0;
//       width: 0;

//       // &:checked ~ span.ingredient-name {
//       //   opacity: 1;
//       // }
//     }
//

.addToFavorite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: $yellow;
  color: white;
  border-radius: 50%;
  margin-left: auto;
  cursor: pointer;
  z-index: +1;
}

.loginRequired {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 80%;
    min-height: 60vh;
    text-align: center;
    margin: 50px auto;
  
    .btn-wrapper {
      margin-top: 3rem;
    }
  }
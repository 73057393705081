// colors
$yellow: #ffd634;
$lightyellow: #fbe799;
$lightgrey: #f2f2f2;
$grey: #d4d4d4;
$darkgrey: #777776;
$white: #f8f8f8;
$black: #171717;

//new colors

$orange: #d96e30;
$purple: #b799bf;
$creme: #fffcf8;
$black: #2b2b2b;

// fonts
$headerFont: "utopia-std", serif;
$primaryFont: "tt-commons-pro", sans-serif;

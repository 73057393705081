.inspirationRecipes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 2rem;
  max-width: calc((4 * 260px) + (3 * 2rem));
  padding: 100px 0;
  margin: 0 auto;

  &__item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 260px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 1rem;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.3));
    padding: 1.5rem;
    margin: 0 auto;
    overflow: hidden;
    transition: 0.2s ease-in;

    &--background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }

      .bg-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          0deg,
          rgba(23, 23, 23, 0.4) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: -1;
        transition: 0.2s ease-in;
      }
    }

    .duration {
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      border-radius: 2rem;
      padding: 0.5rem 1rem;

      svg {
        margin-right: 0.5rem;
      }
    }

    h1 {
      flex: 0 0 100%;
      color: white;
      font-size: 1rem;
      text-align: left;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      margin-top: auto;
    }

    .addToList {
      display: none;
    }

    &:hover {
      transform: scale(1.02);
    }
  }
}

@media screen and (max-width: 1000px) {
  .inspirationRecipes {
    grid-template-columns: repeat(3, 1fr);

    &__item:nth-child(4) {
      display: none;
    }

    &__item {
      filter: none;

      &:hover {
        transform: scale(1);
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .inspirationRecipes {
    grid-template-columns: repeat(2, 1fr);

    &__item:nth-child(4) {
      display: flex;
    }
  }
}

@media screen and (max-width: 550px) {
  .inspirationRecipes {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    padding: 50px 0;
  }
}

.comingSoon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 600px;
  min-height: 60vh;
  text-align: center;
  margin: 50px auto;

  .btn-wrapper {
    margin-top: 3rem;
  }
}

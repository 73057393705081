.recipeOverviewHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 97%;
  min-height: 75vh;
  margin: 0 auto;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $lightgrey;
    text-align: center;
    padding: 4rem;

    h1 {
      max-width: 500px;
    }

    .labels {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      padding-top: 3rem;
      .label {
        display: flex;
        align-items: center;
        background-color: $yellow;
        color: $black;
        font-size: 0.9rem;
        font-weight: 600;
        border-radius: 0.25rem;
        padding: 0.75rem 1.5rem;

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__image {
    max-height: 80vh;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;

    &__text {
      padding: 2rem;
    }
  }
}

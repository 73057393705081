.homeView {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 88px);
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      url("../../assets/images/header.jpg");
    background-size: cover;
    background-position: center;

    &--text {
      color: white;
      text-align: center;
      h1 {
        font-size: 4rem;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 100px;

        span {
          color: $yellow;
        }
      }
    }
  }

  .btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .btn-inverse {
      color: white;
    }
  }

  &__inspiration {
    text-align: center;
    padding: 100px 0;

    &--title {
      padding-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 1000px) {
  .homeView {
    &__header {
      &--text {
        h1 {
          font-size: 3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .homeView {
    &__header {
      &--text {
        h1 {
          font-size: 2.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .homeView {
    &__header {
      &--text {
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}

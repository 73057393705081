.alertPopup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 200;

  &__content {
    max-width: 500px;
    background-color: white;
    text-align: center;
    border-radius: 0.5rem;
    padding: 3rem;

    p {
      padding-top: 2rem;
    }

    .btn-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 1rem;
      padding-top: 2rem;
    }
  }
}
.recipeOverview {
  h1 {
    margin-bottom: 2rem;
  }

  &__content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 4rem;
    width: 97%;
    margin: 4rem auto 4rem auto;

    &--ingredients {
      background-color: $lightgrey;
      padding: 4rem;

      ul {
        list-style-type: none;
        margin-bottom: 2rem;

        li label {
          position: relative;
          display: flex;
          align-items: center;
          text-transform: lowercase;
          margin-bottom: 0.75rem;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ span.ingredient-name {
              opacity: 1;
            }

            &:checked ~ span.checkbox {
              opacity: 1;
              background-color: $yellow;
              border-color: $yellow;
            }

            &:checked ~ span.checkbox .checkbox-inner {
              opacity: 1;
            }
          }

          span.checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            background-color: $yellow;
            border-radius: 50%;
            margin-right: 1rem;
            opacity: 0.4;

            .checkbox-inner {
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
            }
          }

          span.ingredient-name {
            opacity: 0.4;
          }
        }
      }

      a.check-list {
        display: inline-block;
        padding: 2rem 0.75rem 0.5rem;
      }

      .btn-wrapper {
        margin-top: 2rem;
      }
    }

    &--preperation-steps {
      background-color: $lightgrey;
      padding: 4rem;

      .step {
        display: grid;
        grid-template-columns: 50px auto;
        align-items: center;
        gap: 4rem;

        .number {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          background-color: $yellow;
          font-weight: 900;
          border-radius: 50%;
        }

        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }
    }
  }

  .alterRecipeButton {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: $yellow;
    // color: $black;
    border: none;
    border-radius: 0.25rem;
    font-size: 1.25rem;
    padding: 0.75rem;
    z-index: 99;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    &__content {
      gap: 2rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  @media screen and (max-width: 800px) {
    &__content {
      grid-template-columns: 1fr;

      &--ingredients,
      &--preperation-steps {
        padding: 2rem;
      }
    }
  }
}

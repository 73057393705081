.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 1rem 0;
  transition: all 0.25s;
  z-index: 50;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 1rem;
  }

  &__burgermenu {
    display: none;
    background-color: transparent;
    color: $black;
    font-size: 2rem;
    border: none;
    padding-left: 2rem;
    margin-left: auto;
    user-select: none;
  }

  &__sidemenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: $lightgrey;
    opacity: 0;
    transform: translateX(100vw);
    overflow-y: auto;
    transition: transform 0.4s, opacity 0.1s 0.4s;

    &.closed {
      transform: translateX(100vw);
    }

    &.active {
      display: block;
      left: 0;
      right: unset;
      opacity: 1;
      transform: translateX(0);
      transition: transform 0.4s;
    }

    .close-menu {
      position: fixed;
      top: 0;
      right: 1rem;
      background-color: transparent;
      color: $black;
      font-size: 2rem;
      border: none;
      padding-left: 2rem;
    }

    .logo {
      display: block;
      width: 80%;
      height: 100%;
      max-width: 250px;
      margin: 3rem auto 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .sidemenu__items {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 60%;
      margin: 2rem auto;

      hr {
        border: none;
        border-bottom: 2px solid $yellow;
        margin: 2rem 0;
      }

      svg {
        color: $darkgrey;
        margin-right: 1rem;
      }
    }
  }

  &__logo {
    min-width: 140px;
    max-width: 262px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    a {
      position: relative;
      padding: 1rem;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 100%;
        background-color: $yellow;
        border-radius: 2rem;
        z-index: -1;
        transform: translateX(-50%);
        opacity: 0;
        transition: 0.2s ease-in;
      }
    }

    a:hover {
      &::before {
        width: 100%;
        opacity: 1;
        transition: 0.2s ease-in;
      }
    }

    .search-input {
      background-color: $lightgrey;
      color: $darkgrey;
      border-radius: 2rem;
      padding: 0.75rem 1.5rem;
      margin-left: 1rem;

      svg {
        margin-right: 0.5rem;
      }

      input {
        background-color: transparent;
        color: $darkgrey;
        font-weight: 700;
        border: none;
      }

      @media screen and (max-width: 1210px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        padding: 0;

        svg {
          margin: 0;
        }
        input {
          display: none;
        }
      }
    }
  }

  &__icons {
    display: flex;
    gap: 1rem;
    margin-left: auto;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background-color: $yellow;
      font-size: 1.25rem;
      border-radius: 50%;

      &.user {
        background-color: $grey;
      }

      &.list {
        .icon-box {
          position: relative;
        }

        .badge {
          svg {
            position: absolute;
            top: -2px;
            right: -10px;
            width: 15px;
            height: 15px;
            color: black;
            background-color: white;
            border-radius: 50%;
          }
        }
      }
    }

    @media screen and (max-width: 1030px) {
      gap: 0.5rem;
    }
  }

  &-scrolled {
    position: fixed;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    animation: slideDownNav 0.5s ease-in-out;
    transition: all 0.25s;
  }

  &.top {
    position: fixed;
    animation: slideUpNav 0.5s ease-in-out;
    transform: translateY(-100%);
    transition: all 0.25s;
  }
}

// when nav turns into mobile nav
@media screen and (max-width: 1000px) {
  .nav {
    position: fixed;
    &__wrapper {
      grid-template-columns: 1fr 1fr;
      .nav__menu,
      .nav__icons {
        display: none;
      }

      .nav__burgermenu {
        display: block;
      }
    }
  }
}
